$graph-gray: #e4eaff;
$graph-blue: #3964ff;
$graph-sky-blue: #dae5fd;
$graph-dark: #292f36;

$graph-sky-blue-light: rgb($graph-sky-blue, 65%);
$graph-sky-blue-lighter: rgb($graph-sky-blue, 25%);
$graph-blue-light: rgb($graph-blue, 30%);

:root {
    --graph-blue: #{$graph-blue};
    --graph-gray: #{$graph-gray};
    --graph-dark: #{$graph-dark};
    --graph-skyBlue: #{$graph-sky-blue};
    --graph-skyBlueLight: #{$graph-sky-blue-light};
    --graph-skyBlueLighter: #{$graph-sky-blue-lighter};
    --graph-blueLight: #{$graph-blue-light};
}
