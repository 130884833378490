@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
    $color-config: mat.get-color-config($theme);
    $primary-palette: map.get($color-config, 'primary');

    $primary: mat.get-color-from-palette($primary-palette, default);

    .mat-mdc-progress-bar {
        --mdc-linear-progress-active-indicator-color: #{$primary};
    }
}
