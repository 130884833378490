@use '../../styles/palette';
@use 'sass:map';

app-request,
app-relationship-detail-page-export,
app-assessment-detail,
app-artifact-intelligence {
    .mat-mdc-tab-body-wrapper {
        padding: 0 32px;
    }

    .details-tab-active {
        .mat-mdc-tab-body-wrapper {
            background-color: map-get(palette.$viso-surface-container, 500);
        }
    }

    .rdp-title,
    .rdp-subtitle {
        font-size: 1rem;
        line-height: 1.1rem;
    }

    .rdp-title {
        font-weight: bold;
        text-transform: uppercase;
    }

    .rdp-subtitle {
        font-weight: 500;
        color: $text-muted;
    }

    app-assessment-list-item {
        mat-expansion-panel {
            .mat-expansion-panel-header {
                height: 60px;
                .mat-expansion-panel-header-description {
                    color: inherit;
                }
            }
        }
    }

    app-assessment-timeline {
        .assessment-status-timeline {
            app-badge .badge {
                @extend .h5;
                margin-bottom: 0;
            }
        }

        accordion accordion-group {
            .card-header,
            .card {
                background-color: transparent !important;
            }

            .card {
                box-shadow: none;
                margin-bottom: 0;
            }

            .ql-editor {
                padding: 0;
            }
        }
    }

    app-subscribers {
        accordion accordion-group {
            .card {
                box-shadow: none;
                padding-bottom: 0.5rem;
                border-bottom: 1px solid #e5e5e5;
                margin-bottom: 0.55rem;

                .card-header,
                .card-body {
                    padding-left: 0;
                    padding-right: 0;
                    padding-top: 0;
                    padding-bottom: 0;
                }

                &:last-child {
                    border-bottom: none;
                }
            }
        }
    }
}

app-relationship-detail-page-export {
    app-assessment-list .table-responsive,
    app-artifacts-list .ngx-datatable .datatable-body,
    app-comment-list .ql-editor {
        max-height: unset !important;
    }

    app-relationship-details-wrapper {
        [class*='col-'] {
            &.border-end,
            &.border-start {
                border: none !important;
            }
        }

        ngb-accordion .card {
            em.fa.fa-xs {
                display: none;
            }
        }
    }

    @media print {
        * {
            -webkit-print-color-adjust: exact;
        }

        .risk-timeline-card {
            page-break-inside: avoid;

            max-height: 400px;
        }

        .badge {
            border: 0;
        }

        .content-heading {
            display: block !important;
        }

        table {
            thead {
                display: table-row-group;
            }
            tbody {
                tr {
                    page-break-inside: auto !important;
                }
            }
        }

        .trial-disable-icon {
            display: none;
        }
    }
}
