.mdc-list-item__end {
    height: auto !important;
    margin-top: auto !important;
    margin-bottom: auto !important;
}

.mat-mdc-list-base {
    --mdc-list-list-item-leading-avatar-size: 80px !important;
    --mdc-list-list-item-trailing-icon-size: 350px !important;
}

.mat-mdc-list-item:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.mat-mdc-list-item-avatar {
    display: inline-flex !important;
    align-content: center !important;
    flex-wrap: wrap !important;
    height: 100% !important;
    margin-top: auto !important;
    margin-left: 16px !important;
    margin-bottom: auto !important;
}

app-vendor-risk-domains span.mdc-list-item__content {
    max-width: 400px !important;
}
