.fa-user-circle {
    color: #9ca8ad;
}

.custom-icon {
    width: 24px;
    height: 24px;
    display: block;
    content: '';
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    &-zoom-in {
        background-image: url('/content/svg/zoom-in.svg');
    }
    &-zoom-out {
        background-image: url('/content/svg/zoom-out.svg');
    }
}
