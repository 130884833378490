/* Material */
@import './app/shared/styles/material';

/* Bootstrap */
@import './app/shared/styles/bootstrap';
/* Application */
@import './app/shared/styles/app';

/* Default Theme */
@import './app/shared/styles/themes/viso-dark.scss';

/* Import Palette */
@import './app/shared/styles/palette';

/* Import Variables */
@import './app/shared/styles/app/variables';

.ngx-select {
    .ngx-select__item {
        position: relative;

        &.ngx-select__item_active {
            background-color: map-get($viso-secondary-container, 200);
            color: $secondary-container-text;
        }

        .border-line::after {
            content: '';
            display: block;
            position: absolute;
            bottom: -1px;
            left: 0;
            right: 0%;
            height: 1px;
            background-color: #e4e8ef;
        }
    }
}

app-tag-select .ngx-select {
    input.ngx-select__search {
        padding: 0.5rem 1.15rem !important;
    }

    .ngx-select__choices {
        position: relative;
        box-shadow: none;
        border: none;
        padding: 0;
    }

    .ngx-select__item {
        display: flex;
        padding: 0.2rem 1.15rem;
        &.ngx-select__item_active {
            background-color: #f8f9fa;
        }
    }
}

.btn-icon {
    border: none;
    background: none;
    cursor: pointer;

    &[disabled],
    :disabled {
        cursor: not-allowed;
        opacity: 0.75;
    }
}

.cursor-pointer {
    cursor: pointer;
}

.cursor-help {
    cursor: help;
}

a.disabled[routerLink] {
    cursor: default;
    pointer-events: none;
}

.notification-badge {
    .mat-badge-content {
        background-color: #fff500;
        color: $dark;
        border: solid 1px $dark;
        right: -6px !important;
        box-sizing: content-box;
        top: -2px !important;
        font-size: 13px;
    }
}

[appmiddletruncate] {
    display: block;
    white-space: nowrap;
    overflow-x: hidden;
}

.text-bg-warning {
    background-color: #fff4bb !important;
    padding: 0.625rem;
    margin-right: auto;
}

.mat-expansion-indicator::after {
    color: map-get($viso-primary, 500) !important;
}

.mat-mdc-tab-header-pagination-chevron {
    border-style: solid;
    border-width: 2px 2px 0 0;
    height: 0.5rem;
    width: 0.5rem;
    border-color: map-get($viso-primary, 500) !important;
}

.mat-mdc-paginator-icon {
    fill: map-get($viso-primary, 500) !important;
}

.mat-mdc-icon-button[disabled] .mat-mdc-paginator-icon {
    fill: map-get($viso-primary, 100) !important;
}

.color-primary {
    color: map-get($viso-primary, 500) !important;
}

.px-8 {
    padding-left: 32px !important;
    padding-right: 32px !important;
}
