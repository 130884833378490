.popover {
    font-family: 'Niveau Grotesk';
    width: 260px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 3px;

    &.no-arrow {
        .arrow {
            display: none;
        }
    }

    &.wider-popover {
        width: 400px;
        max-width: 400px;
    }

    .popover-content {
        padding: 0px;
    }
}

.popover-detail {
    border-top: 1px solid #cfdbe2;
    font-size: 0.79rem;
    line-height: 0.889rem;
    padding: 0.7rem;

    .popover-detail-label {
        color: #000;
        font-weight: 500;
    }

    .popover-detail-text {
        color: #4f4f4f;
    }

    .popover-link {
        color: #2ec6bb;
    }
}

.popover-footer {
    border-top: 1px solid #cfdbe2;
    padding: 0.25rem 0.7rem;

    .popover-footer-label {
        font-size: 0.79rem;
        color: #000;
        font-weight: 500;
    }
}

.popover-header {
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0 0.7rem;
    background-color: #fff;
    border: none;

    .popover-icon {
        height: 25px;
        max-width: 25px;
        min-width: 25px;
        background-color: #c4c4c4;
        border-radius: 50%;
        margin-right: 0.5rem;

        &.white-bg {
            background-color: #fff;
        }
    }

    .popover-title {
        font-size: 0.889rem;
        color: #000;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.tooltip {
    font-size: 0.79rem;
}

.popover.assessment-popover {
    p,
    li {
        font-size: 0.85rem;
    }

    ul li {
        margin-bottom: 0.5rem;
    }
}

.popover.control-domain-all-artifacts-coverage {
    width: auto;
    padding: 0.7rem;
    font-size: 13px;

    .popover-content {
        border: 0;
    }
}

.popover-active {
    z-index: 10;
}
