@import '../../styles/app/variables.scss';

.ngx-datatable {
    .datatable-body .datatable-row-left {
        transform: none !important;
        position: sticky;
        left: 0;
        border-right: 1px solid #eee;
    }
    .datatable-body .datatable-row-right {
        transform: none !important;
        position: sticky;
        right: 0;
        border-left: 1px solid #eee;
    }

    .datatable-header {
        min-width: 100%;

        .datatable-header-inner {
            background-color: #e4e8ef;
        }

        .datatable-header-cell {
            font-size: 0.79rem;
            font-weight: bold;
            border: 1px solid #eee;
            height: 30px;
            padding: 0.35em 0.95em !important;
            background-color: #e4e8ef;
            border-right: none;
            color: #000000;
            text-indent: initial;
            border-spacing: 2px;
            overflow: hidden;

            &.sortable {
                .sort-btn:before {
                    line-height: 10px;
                    vertical-align: top;
                    font-size: 0.79rem;
                    color: #1a2041;
                    padding-left: 0.2rem;
                }

                .sort-btn.datatable-icon-down:before {
                    font-family: 'Font Awesome 5 Free' !important;
                    font-weight: 600 !important;
                    content: '\f063';
                }

                .sort-btn.datatable-icon-up:before {
                    font-family: 'Font Awesome 5 Free' !important;
                    font-weight: 600 !important;
                    content: '\f062';
                }
            }

            &.group-ending {
                border-right: 1px solid #f5f7fa;
            }
        }
    }

    .datatable-body {
        height: auto !important;
        max-width: 100%;

        @media (min-width: 1440px) {
            width: 100% !important;
        }

        .datatable-scroll {
            width: unset !important;
        }

        .datatable-body-row {
            background-color: #fff !important;
            border-top: 1px solid #eee !important;

            &.ellipsis-label {
                .datatable-body-cell-label {
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            .datatable-body-cell {
                font-size: 0.79rem;
                border-right: none;
                line-height: 12px;

                &.cell-padding {
                    padding-top: 0.6rem;
                }

                &.cell-centered {
                    display: flex;
                    align-items: center;
                    padding: 0 12px;
                }

                &.group-ending {
                    border-right: 1px solid #e4e8ef;
                }

                &.actions-flex-cell {
                    .datatable-body-cell-label {
                        display: flex;
                    }

                    &.space-between {
                        .datatable-body-cell-label {
                            justify-content: space-between;
                        }
                    }
                }

                &:not(:last-child) {
                    border-right: 1px solid #eee;
                }
            }

            .ngx-dt-action {
                color: #fff !important;
                &.persistent {
                    color: #2ec6bb !important;
                }
            }

            &:hover {
                background-color: #fafbff !important;
                transition: all 0.3s ease-in-out;

                .datatable-row-left {
                    background-color: #fafbff !important;
                    transition: all 0.3s ease-in-out;
                }

                .ngx-dt-action {
                    font-style: normal !important;
                    color: #2ec6bb !important;
                    cursor: pointer;
                    display: block;
                }
            }

            &.inactive,
            &.inactive.active {
                color: #929292;

                .ngx-dt-action {
                    color: #f6f6f6 !important;

                    &:hover {
                        color: #2ec6bb !important;
                    }
                }

                a {
                    color: #929292 !important;
                }
                .assurance-level-dot {
                    color: #929292 !important;
                    background-color: #929292 !important;
                }
            }

            &.active {
                color: #292f36 !important;
                background-color: #edfffe !important;

                .datatable-row-left {
                    background-color: #edfffe !important;
                }
            }
        }

        .datatable-row-left {
            background-color: #fff;
        }

        .datatable-row-right {
            left: 15px;
        }

        .progress-linear {
            display: block;
            position: relative;
            width: 100%;
            height: 5px;
            padding: 0;
            margin: 0;

            .container {
                max-width: 100%;
                display: block;
                position: relative;
                overflow: hidden;
                width: 100%;
                height: 4px;
                -webkit-transform: translate(0, 0) scale(1, 1);
                transform: translate(0, 0) scale(1, 1);
                background-color: #b8e9e5;

                .bar {
                    transition: all 0.2s linear;
                    -webkit-animation: query 0.8s infinite cubic-bezier(0.39, 0.575, 0.565, 1);
                    animation: query 0.8s infinite cubic-bezier(0.39, 0.575, 0.565, 1);
                    transition: -webkit-transform 0.2s linear;
                    transition: transform 0.2s linear;
                    background-color: #2ec6bb;
                    position: absolute;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    width: 100%;
                    height: 4px;
                }
            }
        }

        @keyframes query {
            0% {
                opacity: 1;
                transform: translateX(35%) scale(0.3, 1);
            }
            100% {
                opacity: 0;
                transform: translateX(-50%) scale(0, 1);
            }
        }
    }

    .datatable-footer {
        background-color: transparent !important;
        color: $body-color !important;
        margin-top: -4px !important;
        padding-top: 2px;
        border-top: 1px solid #eee;
        overflow: visible !important;

        .datatable-pager {
            a {
                color: $primary !important;
            }

            li.active {
                a {
                    background-color: $primary !important;
                    color: white !important;
                }
            }

            li:not(.disabled):hover a {
                background-color: #e9ecef !important;
                color: black !important;
            }
        }
    }
}

@for $i from 1 through 50 {
    .order-#{$i} {
        order: #{$i};
    }
}

app-artifacts-list .ngx-datatable .datatable-body {
    max-height: 205px !important;
}
app-data-types-config .ngx-datatable.bootstrap {
    overflow-wrap: break-word;
}
app-risk-timeline-list .ngx-datatable .datatable-body {
    max-height: 400px !important;
}
app-assessment-list .ngx-datatable .datatable-body {
    max-height: 400px;

    .empty-row {
        text-align: center;
        @extend .text-muted;
    }
}

relationshiprisk .ngx-datatable .datatable-body-cell span > span {
    margin-bottom: 3px;
}
