// Bootstrap
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import '../app/variables';

//
// Variables to change general look
//

// LAYOUT
html * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

$content-bg: #fff;
$aside-bg: #fff;
// NAVBAR TOP

$nav-top-bg: #fafbfb;
$nav-top-bg-start: $nav-top-bg;
$nav-top-bg-end: #fff500;
$nav-header-bg: #fff500;
$basic-nav-top-bg: #fff500;

$nav-top-item-mobile: $nav-top-bg;
$nav-top-item-mobile-active: darken($nav-top-item-mobile, 20%);

$nav-top-item-desktop: #fff;
$nav-top-item-desktop-active: darken($nav-top-item-desktop, 20%);

// SIDEBAR

$sidebar-bg: #01072c;

$sidebar-item-color: $gray-200;
$sidebar-item-color-active: #fff500;
$sidebar-item-bg-active: #1a2041;

$sidebar-icon-color: inherits;
$sidebar-icon-color-active: $nav-top-bg;

$sidebar-bullet-color: rgba(0, 0, 0, 0.25);
$sidebar-bullet-color-active: $sidebar-icon-color-active;

$sidebar-heading-color: #919da8;
$sidebar-label-color: $sidebar-item-color;

// OFFSIDEBAR

$offsidebar-bg: #fff;
$offsidebar-border-color: greyscale(darken($offsidebar-bg, 20%));
$offsidebar-color: $body-color;

/* ========================================================================
   Component: layout
 ========================================================================== */
body,
.wrapper .section-container {
    background-color: $content-bg !important; // Using !important to revert Paper CSS background rule
}

.wrapper .aside-container {
    background-color: $aside-bg;
}

/* ========================================================================
   Component: top-navbar
 ========================================================================== */

// Navbar Mobile General styles
// ------------------------------
.basic-navbar.topnavbar {
    background-color: $basic-nav-top-bg;
}

.topnavbar {
    background-color: $nav-top-bg;
    .navbar-header {
        background-color: $nav-header-bg;
        border-width: 0px 1px 1px 0px;
        border-style: solid;
        border-color: #ccc402;

        .expand-button {
            background-color: $nav-header-bg;
            border-color: #ccc402;
            border-width: 1px 1px 1px 0px;
            border-style: solid;
            border-top-right-radius: 50%;
            border-bottom-right-radius: 50%;
        }

        @include media-breakpoint-up(md) {
            background-image: none;
        }
    }

    .navbar-nav > li > .navbar-text {
        color: $nav-top-item-mobile;
    }

    .navbar-nav > li > a,
    .navbar-nav > .open > a {
        color: $nav-top-item-mobile;

        &:hover,
        &:focus {
            color: $nav-top-item-mobile-active;
        }
    }

    // Navbar link active style
    .navbar-nav > .active > a,
    .navbar-nav > .open > a {
        &,
        &:hover,
        &:focus {
            background-color: transparent;
        }
    }

    // the button to toggle search
    .navbar-nav > li > [data-toggle='navbar-search'] {
        color: $nav-top-item-desktop;
    }

    .nav-wrapper {
        background-color: $nav-top-bg;
    }
}

// Navbar Desktop styles
// ------------------------------

@include media-breakpoint-up(md) {
    // Navbar top styles
    .topnavbar {
        background-color: $nav-top-bg;
        .navbar-nav > .open > a {
            &,
            &:hover,
            &:focus {
                box-shadow: 0 -3px 0 rgba(255, 255, 255, 0.5) inset;
            }
        }

        .navbar-nav > li > .navbar-text {
            color: $nav-top-item-desktop;
        }

        .navbar-nav > li > a,
        .navbar-nav > .open > a {
            color: $nav-top-item-desktop;

            &:hover,
            &:focus {
                color: $nav-top-item-mobile-active;
            }
        }
    }

    // .topnavbar
}

/* ========================================================================
   Component: sidebar
 ========================================================================== */
.sidebar {
    background-color: $sidebar-bg;

    .nav-heading {
        color: $sidebar-heading-color;
    }
}

.dropdown.sidebar-dropdown {
    background-color: $sidebar-bg;
    > .dropdown-menu {
        background-color: $sidebar-bg;

        > .dropdown-item,
        > disable-on-trial-wrapper > span > .dropdown-item:not(.disabled) {
            color: $sidebar-item-color;
            &:focus,
            &:hover,
            &.active {
                border-left-color: $sidebar-item-color-active;
            }
        }

        > disable-on-trial-wrapper > span > .dropdown-item.disabled {
            &:focus,
            &:hover,
            &.active {
                color: #515253 !important;
            }
        }
    }
}

// Items
.sidebar-nav {
    > li {
        > a,
        > .nav-item,
        > .dropdown > a.dropdown-toggle,
        > disable-on-trial-wrapper > span > a {
            color: $sidebar-item-color;

            &:hover {
                color: $sidebar-item-color-active;
            }

            // Item icon
            > em {
                color: $sidebar-icon-color;
            }
        }

        // Active item state
        &.active,
        &.open {
            &,
            > a,
            > .nav-item,
            .sidebar-nav {
                background-color: $sidebar-item-bg-active;
            }

            > .nav-item > em,
            > a > em {
                color: $sidebar-icon-color-active;
            }
        }

        &.active {
            border-left-color: $sidebar-item-color-active;
        }
    }
}

.sidebar-subnav {
    background-color: $sidebar-bg;

    > .sidebar-subnav-header {
        color: $sidebar-item-color;
    }

    > li {
        > a,
        > .nav-item {
            color: $sidebar-item-color;

            &:hover {
                color: $sidebar-item-color-active;
            }
        }

        &.active {
            > a,
            > .nav-item {
                &:after {
                    border-color: $sidebar-bullet-color-active;
                    background-color: $sidebar-bullet-color-active;
                }
            }
        }
    }

    // li
}

/* ========================================================================
   Component: offsidebar
 ========================================================================== */
.offsidebar {
    border-left: 1px solid $offsidebar-border-color;
    background-color: $offsidebar-bg;
    color: $offsidebar-color;
}
