@use './variables';

app-assessment-no-longer-in-progress,
app-assessment-collection,
app-assessment-collection-authentication {
    .assessment-collection-step-title {
        margin-bottom: 1rem;
        font-size: 1.5rem;
        font-weight: normal;
    }
}

app-assessment-collection {
    // Material classes //

    .mat-mdc-list-item {
        &:not(:last-child) {
            border-bottom: none !important;
        }
        --mdc-list-list-item-label-text-size: --mat-expansion-header-text-size;
    }

    .mdc-button__label {
        font-weight: bold;
    }

    .mdc-dialog__container {
    }

    // Custom components //

    app-artifact-collection-step {
        mat-checkbox {
            .mdc-form-field {
                width: 100%;
            }
        }
    }

    app-file-artifact-item {
        .mat-mdc-card {
            border-radius: 10px;

            .mat-mdc-card-content {
                padding: 10px 16px;
            }
        }
    }

    // Custom classes //

    // Override recommendations list group title sizes to app default font size (14px).
    .group-header {
        font-size: 0.875rem !important;
    }

    .container-full {
        padding: 2rem;
        border: 1px solid variables.$gray-light;
        border-radius: 8px;
    }

    $container-min-width: 900px;

    .container-top-half {
        padding: 2rem;
        border-top: 1px solid;
        border-left: 1px solid;
        border-right: 1px solid;
        border-color: variables.$gray-light;
        border-radius: 8px 8px 0 0;
        min-width: $container-min-width;
    }

    .container-bottom-half {
        padding: 1rem;
        border-left: 1px solid;
        border-right: 1px solid;
        border-bottom: 1px solid;
        border-color: variables.$gray-light;
        border-radius: 0 0 8px 8px;
        min-width: $container-min-width;

        &:not(.no-divider) {
            border-top: 1px solid variables.$gray-light;
        }
    }
}
