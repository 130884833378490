app-security-control-domains {
    app-control-group {
        mat-card {
            overflow-x: hidden;
            &.disabled,
            &.out-of-scope {
                background-color: #fafbfb;

                .mat-expansion-panel {
                    background-color: #fafbfb;
                }
            }
        }
        mat-accordion {
            .mat-expansion-panel {
                @extend .border-top;
            }
            .mat-expansion-panel-header {
                @extend .ps-2;
            }
            .mat-expansion-panel-body {
                padding-left: 0 !important;
                padding-right: 0 !important;
            }
        }
    }
}
