@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
    $color-config: mat.get-color-config($theme);
    $warn-palette: map.get($color-config, 'warn');
    $title-height: 60px;

    .mat-mdc-dialog-container {
        .mat-mdc-dialog-title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: $title-height;

            &.dynamic-height {
                height: auto;
            }

            &:before {
                content: none;
            }
        }

        .mdc-dialog__title {
            padding: 1rem 0.5rem 1rem 1rem;
            font-weight: bold !important;
        }

        .mat-mdc-dialog-content.mdc-dialog__content {
            overflow-y: auto;
            height: 100%;
            max-height: calc(85vh - $title-height) !important;
            letter-spacing: normal;
            color: initial;
            font-size: inherit;
            padding: 0.5rem 1rem 1rem 1rem !important;
        }
    }

    .custom-dialog {
        .mat-mdc-dialog-container .mat-mdc-dialog-surface {
            background-color: #fcf8fc;
        }
    }
}
