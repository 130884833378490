@import 'variables';

app-create-edit-relationship-modal {
    form {
        .form-group {
            small {
                font-size: 90%;
            }

            .radio.c-radio label {
                font-weight: normal;
            }

            .radio-primary {
                input[type='radio']:checked + label:after {
                    top: 7px;
                }
            }
        }

        legend {
            font-size: 1.2rem;
        }
    }

    app-vendor {
        .ngx-select .ngx-select__item-group:last-child .ngx-select__item:not(.active) {
            color: #1a2041 !important;
        }
    }

    aw-wizard {
        aw-wizard-navigation-bar {
            position: relative;
            &::after {
                content: '';
                width: calc(100% + 3.125rem);
                height: 1px;
                background-color: $gray;
                bottom: 0px;
                left: -1.5625rem;
                position: absolute;
            }
        }
        .steps-indicator {
            padding-bottom: 24px !important;
        }

        .wizard-steps.horizontal {
            padding-top: 1.0625rem;
        }

        &.hide-inner-wizard {
            aw-wizard-navigation-bar.horizontal ul.steps-indicator {
                display: none !important;
            }

            .wizard-steps.horizontal {
                padding-top: 0;
            }
        }
    }

    app-data-types,
    app-business-cases {
        .card-warning {
            height: 36px;
            background: #f7d54866;
        }

        form {
            .checkbox {
                width: 50%;
                position: relative;

                .description-text {
                    display: none;
                    position: absolute;
                    top: 0;
                    left: 100%;
                    background-color: #ffffff;
                    width: 100%;
                    margin-top: 0;
                }

                &:hover {
                    .description-text {
                        display: block;
                    }
                }
            }
        }
    }

    app-data-types {
        form {
            .checkbox-container .checkbox {
                &:nth-last-child(-n + 3) {
                    .description-text {
                        transform: translateY(-25%);
                    }
                }

                &:nth-of-type(13) {
                    .description-text {
                        transform: translateY(-50%);
                    }
                }

                &:last-child {
                    .description-text {
                        transform: translateY(-100%);
                    }
                }
            }
        }
    }

    app-business-cases {
        form {
            .checkbox {
                &:last-child {
                    .description-text {
                        transform: translateY(-50%);
                    }
                }
            }
        }
    }
}
