@import 'variables';

.viso-heading {
    border-bottom: none;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    line-height: 1.1;
    color: $heading-color;
    margin: -15px;
    margin-bottom: 20px;
    padding: 15px;
    font-weight: normal;

    .viso-subheading {
        font-size: 0.75rem;
        font-weight: 400;
        line-height: 1rem; /* 133.333% */
        letter-spacing: 0.4px;
    }

    .viso-legend {
        font-size: 0.6875rem;
        font-weight: 500;
        letter-spacing: 0.5px;
        margin-right: 20px;

        .title {
            text-align: end;
            font-size: 0.75rem;
            font-weight: 400;
            line-height: 1rem; /* 133.333% */
            letter-spacing: 0.4px;
            margin-bottom: 0px !important;
        }
    }
}

.viso-mat-list {
    .title {
        color: $list-title;
        font-size: 0.6875rem;
        font-weight: 900;
        line-height: 1rem; /* 145.455% */
        letter-spacing: 0.5px;
        text-transform: uppercase;
        --mdc-list-list-item-hover-label-text-color: #{$list-title};

        &.custom {
            color: $list-title-custom;
            --mdc-list-list-item-hover-label-text-color: #{$list-title-custom};
        }
    }

    .name {
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.5rem; /* 150% */
        letter-spacing: 0.15px;
        color: $list-name;
    }

    .description {
        font-size: 0.875rem;
        letter-spacing: 0.25px;
        color: $list-description;
        white-space: pre-wrap !important;
        margin-bottom: 1rem;
    }
}
