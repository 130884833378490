@use 'sass:map';
@use '@angular/material' as mat;

mat-icon.mat-icon {
    &.no-padding {
        padding: 0;
    }
}

@mixin color($theme) {
    $color-config: mat.get-color-config($theme);
    $primary-palette: map.get($color-config, 'primary');
    $secondary-palette: map.get($color-config, 'secondary');
    $accent-palette: map.get($color-config, 'accent');

    .icon-primary {
        color: mat.get-color-from-palette($primary-palette, default);
    }

    .icon-accent {
        color: mat.get-color-from-palette($accent-palette, default);
    }

    .icon-secondary {
        color: mat.get-color-from-palette($secondary-palette, default);
    }
}

@mixin typography($theme) {
    $typography-config: mat.get-typography-config($theme);
}

@mixin theme($theme) {
    $color-config: mat.get-color-config($theme);
    @if $color-config != null {
        @include color($theme);
    }

    $typography-config: mat.get-typography-config($theme);
    @if $typography-config != null {
        @include typography($theme);
    }

    .icon-small {
        font-size: 1rem !important;
    }
}
