@use '../palette';

$no-context: map-get(palette.$viso-default-custom, no-context);
$low-risk: map-get(palette.$viso-default-custom, low-risk);
$medium-risk: map-get(palette.$viso-default-custom, medium-risk);
$high-risk: map-get(palette.$viso-default-custom, high-risk);
$extreme-risk: map-get(palette.$viso-default-custom, extreme-risk);

$data-transfer: #334fc7;
$network-connection: $low-risk;
$data-storage: $medium-risk;
$physical-access: $high-risk;
$personnel-data-access: $extreme-risk;
$software-hardware-supplier: $no-context;

:root {
    --business-case-dataTransfer: #{$data-transfer};
    --business-case-networkConnection: #{$network-connection};
    --business-case-dataStorage: #{$data-storage};
    --business-case-physicalAccess: #{$physical-access};
    --business-case-personnelDataAccess: #{$personnel-data-access};
    --business-case-softwareHardwareSupplier: #{$software-hardware-supplier};
}
