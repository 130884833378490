app-relationships {
    .content-heading {
        border-bottom: none !important;
    }

    .quick-filter {
        .quick-filter-option {
            app-risk-chip {
                margin-left: 1rem;
                > div {
                    padding: 2px 4px !important;
                }
                span {
                    display: none;
                }
            }
        }
    }
    .dropup .dropdown-menu {
        min-width: 0 !important;
    }

    .dropdown-menu-edge-left {
        left: -150px !important;
    }

    > div app-radiobutton-group {
        display: inline-block !important;
    }

    .keyword-filter {
        font-size: 1rem;
        .mdc-text-field--outlined {
            --mdc-outlined-text-field-container-shape: 28px !important;
        }
    }

    .list-graph-tab-group {
        .mat-mdc-tab-labels {
            display: none;
        }
    }
}
