.modal-dialog .modal-content {
    app-announcement-modal {
        .ql-editor {
            padding: 0;
        }
    }

    ngb-carousel {
        padding-bottom: 1.5rem;

        .carousel-indicators {
            bottom: 0;
            margin-bottom: 0;

            > li {
                width: 0.5rem;
                height: 0.5rem;
                border-radius: 50%;
                background-color: #dfe6e9;

                &.active {
                    background-color: $primary;
                }
            }
        }

        .carousel-inner {
            > .carousel-item {
                text-align: center;
            }
        }

        .carousel-control-prev,
        .carousel-control-next {
            width: 1rem;

            .carousel-control-prev-icon,
            .carousel-control-next-icon {
                font-family: 'Font Awesome 5 Free';
                font-weight: 900;
                color: $primary;
                transform: translateY(-0.75rem);
            }
        }

        .carousel-control-prev {
            transform: translateX(-1rem);
            .carousel-control-prev-icon {
                background-image: none;
                &::before {
                    content: '\f053';
                }
            }
        }
        .carousel-control-next {
            transform: translateX(1rem);
            .carousel-control-next-icon {
                background-image: none;
                &::before {
                    content: '\f054';
                }
            }
        }

        &.no-controls {
            padding-bottom: 0;
        }
    }
}
