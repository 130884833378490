.action-notification {
    font-size: 0.8rem;
    transition: opacity 0.6s ease-in-out;
    opacity: 0;

    &.active {
        opacity: 1;
    }
}

.action-notification:before {
    content: attr(data-text);
    position: absolute;
    top: -50px;
    transform: translateX(-50%);
    padding: 9px;
    background-color: #000;
    color: #fff;
    text-align: center;
}
