/* ========================================================================
     Component: typo
 ========================================================================== */

@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,400italic);
@font-face {
    font-family: 'Niveau Grotesk';
    src: url('fonts/HVD Fonts - NiveauGroteskRegular.otf') format('OpenType');
    font-weight: normal;
}

@mixin bold-font {
    font-family: 'Niveau Grotesk';
    src: url('fonts/HVD Fonts - NiveauGroteskMedium.otf') format('OpenType');
}

// Material uses a font-weight of 500 for buttons, headers, etc., which is between normal and bold.
// Since our default bold value for the app is really just medium boldness, we don't need to
// distinguish between the two and can use the same font-face for both 500 and bold weights.
@font-face {
    @include bold-font;
    font-weight: 500;
}

@font-face {
    @include bold-font;
    font-weight: bold;
}

body {
    font-family: 'Niveau Grotesk', sans-serif;
    color: $body-color;
    font-size: 0.875rem; // 14px
}

h1,
h2,
h3,
h4 {
    font-weight: bold;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    margin-bottom: 0.65625rem;
}

h1,
.h1 {
    font-size: 2.25rem; // 36px
}

h2,
.h2 {
    font-size: 1.875rem; // 30px
}

h3,
.h3 {
    font-size: 1.5rem; // 24px
}

h4,
.h4 {
    font-size: 1.125rem; // 18px
}

h5,
.h5 {
    font-size: 0.875rem;
}

h6,
.h6 {
    font-size: 1rem;
}

p {
    margin-bottom: 0.65rem; // ~10px
}
