$tooltip-background-color: #1b1b1e;
@mixin theme($theme) {
    .mdc-tooltip > .mdc-tooltip__surface {
        --mdc-plain-tooltip-container-color: #1b1b1e;
        --mdc-plain-tooltip-supporting-text-color: #ffffff;
        box-shadow:
            0px 2px 6px 2px rgba(0, 0, 0, 0.15),
            0px 1px 2px 0px rgba(0, 0, 0, 0.3);
    }

    .mat-mdc-tooltip-panel-right {
        .tooltip-indicator {
            &::after {
                position: absolute;
                content: '';
                display: inline-block;
                background-color: $tooltip-background-color;
                clip-path: polygon(50% 0, 0 50%, 50% 100%);
                left: -8px;
                width: 16px;
                height: 16px;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }

    .mat-mdc-tooltip-panel-left {
        .tooltip-indicator {
            &::after {
                position: absolute;
                content: '';
                display: inline-block;
                background-color: $tooltip-background-color;
                clip-path: polygon(50% 0, 100% 50%, 50% 100%);
                right: -8px;
                width: 16px;
                height: 16px;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }
}
