@use '../palette';
@use './variables';

quill-editor {
    display: block;
    background-color: #ffffff;

    .ql-toolbar {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .fast-mentions-container {
            align-self: end;
            flex-grow: 1;
            display: flex;
            justify-content: flex-end;
            justify-self: flex-end;
        }

        button.mention-button {
            width: auto;
            height: auto;
        }
    }
}
quill-view {
    .ql-container.ngx-quill-view {
        border: 0;
    }
    &.no-padding {
        .ql-editor {
            padding: 0px;
        }
    }
}

.ql-snow .mention {
    color: variables.$secondary-container-text;
    background-color: map-get(palette.$viso-secondary-container, 200) !important;

    a {
        color: inherit;
    }
}

quill-view,
quill-editor {
    .ql-editor {
        font-family: 'Niveau Grotesk', sans-serif;
    }
}

app-create-comment,
app-comment-list {
    .ql-editor {
        max-height: 200px;
        overflow-y: auto;

        @media print {
            max-height: none;
        }
    }
}
