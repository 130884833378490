form {
    legend {
        font-size: 1rem;
        font-weight: bold;
    }

    .form-group {
        label {
            font-weight: bold;
        }

        &.required {
            > label:after {
                content: '*';
                color: $danger;
                margin-left: 0.2rem;
            }
        }

        .ng-valid[required],
        .ng-valid.required {
            border-left: 5px solid $success; /* green */
        }

        // show left error border on inputs
        input.ng-invalid:not(form):not(.ng-untouched) {
            border-left: 5px solid $danger; /* red */
        }

        // show left error border on ngx-select
        .ng-invalid:not(form):not(.ng-untouched) {
            .ngx-select__toggle {
                border-left: 5px solid $danger; /* red */
            }
        }
    }

    .form-subtitle {
        color: #9ca8ad;
        border-bottom: 1px solid #dfe6e9;
    }
}
