/* ========================================================================
     Component: dropdown-extras
 ========================================================================== */

// Allows to mix list group inside dropdowns
.dropdown-list {
    .dropdown-item {
        padding: 0;
    }

    > .dropdown-menu {
        padding: 0;
    }

    .list-group {
        margin: 0;
    }

    .list-group-item {
        border-radius: 0;
        border-left: 0;
        border-right: 0;
        &:first-child {
            border-top: 0;
        }
        &:last-child {
            border-bottom: 0;
        }
    }
}

// Labels inside dropdowns
.dropdown {
    > a {
        position: relative;
        > .badge {
            position: absolute;
            top: 10px;
            right: 0;
            padding: 2px 5px;
        }
    }
}

// Popper doesnt place correctly dropdowns
// we can use forced class to place them
.dropdown-menu-end-forced {
    right: 0 !important;
    left: auto !important;
}

// Place the dropdown inside the window if the button is on the edge
.dropdown-menu-edge-left {
    left: -187px !important;
}

// Remove default icon from dropdowns
.dropdown-toggle-nocaret {
    &:after {
        display: none;
    }
}

.dropdown-item-highlight-red {
    color: #ed768a !important;
}

bs-dropdown-container {
    z-index: 10000 !important;

    .translate-x {
        transform: translateX(var(--data-translate-value));
    }
}

.dropdown-menu .dropdown-item {
    cursor: pointer;
}
