// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$dark-primary-text: black;
$light-primary-text: white;
$on-secondary-container: #004f58;
$surface-container-text: #0d154b;

// Viso Trust primary palette #3646b8
$viso-primary: (
    50: #e7e9f6,
    100: #c3c8ea,
    200: #9ba3dc,
    300: #727ecd,
    400: #5462c3,
    500: #3646b8,
    600: #303fb1,
    700: #2937a8,
    800: #222fa0,
    900: #162091,
    A100: #dee6ff,
    A200: #abbfff,
    A400: #7898ff,
    A700: #5e84ff,
    contrast: (
        50: $dark-primary-text,
        100: $dark-primary-text,
        200: $dark-primary-text,
        300: $light-primary-text,
        400: $light-primary-text,
        500: $light-primary-text,
        600: $light-primary-text,
        700: $light-primary-text,
        800: $light-primary-text,
        900: $light-primary-text,
        A100: $light-primary-text,
        A200: $light-primary-text,
        A400: $light-primary-text,
        A700: $light-primary-text,
    ),
);

// Viso Trust secondary palette
$viso-secondary-container: (
    50: #edfcff,
    100: #d1f9ff,
    200: #b2f5ff,
    300: #93f0ff,
    400: #7cedff,
    500: #65eaff,
    600: #5de7ff,
    700: #53e4ff,
    800: #49e1ff,
    900: #37dbff,
    A100: #ffffff,
    A200: #ffffff,
    A400: #ffffff,
    A700: #eefeff,
    contrast: (
        50: $on-secondary-container,
        100: $on-secondary-container,
        200: $on-secondary-container,
        300: $on-secondary-container,
        400: $on-secondary-container,
        500: $on-secondary-container,
        600: $on-secondary-container,
        700: $on-secondary-container,
        800: $on-secondary-container,
        900: $on-secondary-container,
        A100: $on-secondary-container,
        A200: $on-secondary-container,
        A400: $on-secondary-container,
        A700: $on-secondary-container,
    ),
);

$viso-secondary: (
    50: #e0edee,
    100: #b3d2d6,
    200: #80b4ba,
    300: #4d959e,
    400: #267f8a,
    500: #006875,
    600: #00606d,
    700: #005562,
    800: #004b58,
    900: #003a45,
    A100: #88ffea,
    A200: #55ffe1,
    A400: #22ffd8,
    A700: #09ffd4,
    contrast: (
        50: $dark-primary-text,
        100: $dark-primary-text,
        200: $dark-primary-text,
        300: $dark-primary-text,
        400: $light-primary-text,
        500: $light-primary-text,
        600: $light-primary-text,
        700: $light-primary-text,
        800: $light-primary-text,
        900: $light-primary-text,
        A100: $dark-primary-text,
        A200: $dark-primary-text,
        A400: $dark-primary-text,
        A700: $dark-primary-text,
    ),
);

$viso-accent: (
    50: #e4e4e8,
    100: #babcc6,
    200: #8d90a0,
    300: #5f637a,
    400: #3c415e,
    500: #1a2041,
    600: #171c3b,
    700: #131832,
    800: #0f132a,
    900: #080b1c,
    A100: #66b9ff,
    A200: #33a2ff,
    A400: #008bff,
    A700: #007de5,
    contrast: (
        50: $dark-primary-text,
        100: $dark-primary-text,
        200: $dark-primary-text,
        300: $dark-primary-text,
        400: $dark-primary-text,
        500: $dark-primary-text,
        600: $dark-primary-text,
        700: $dark-primary-text,
        800: $dark-primary-text,
        900: $dark-primary-text,
        A100: $dark-primary-text,
        A200: $dark-primary-text,
        A400: $dark-primary-text,
        A700: $dark-primary-text,
    ),
);

// Viso Trust neutral palette
$viso-neutral: (
    50: #efeff0,
    100: #d7d8d8,
    200: #bdbebf,
    300: #a3a4a5,
    400: #8f9191,
    500: #7b7d7e,
    600: #737576,
    700: #686a6b,
    800: #5e6061,
    900: #4b4d4e,
    A100: #dcf7fb,
    A200: #adebf7,
    A400: #72e9ff,
    A700: #59e5ff,
    contrast: (
        50: $dark-primary-text,
        100: $dark-primary-text,
        200: $dark-primary-text,
        300: $dark-primary-text,
        400: $dark-primary-text,
        500: $dark-primary-text,
        600: $light-primary-text,
        700: $light-primary-text,
        800: $light-primary-text,
        900: $light-primary-text,
        A100: $dark-primary-text,
        A200: $dark-primary-text,
        A400: $dark-primary-text,
        A700: $dark-primary-text,
    ),
);

$neutral-variant: (
    50: #efeff0,
    100: #d6d6d9,
    200: #bbbbc0,
    300: #a09fa6,
    400: #8b8b93,
    500: #777680,
    600: #6f6e78,
    700: #64636d,
    800: #5a5963,
    900: #474650,
    A100: #d9dbfc,
    A200: #a9aff9,
    A400: #707bff,
    A700: #5663ff,
    contrast: (
        50: $dark-primary-text,
        100: $dark-primary-text,
        200: $dark-primary-text,
        300: $dark-primary-text,
        400: $dark-primary-text,
        500: $dark-primary-text,
        600: $light-primary-text,
        700: $light-primary-text,
        800: $light-primary-text,
        900: $light-primary-text,
        A100: $dark-primary-text,
        A200: $dark-primary-text,
        A400: $dark-primary-text,
        A700: $dark-primary-text,
    ),
);

$viso-success: (
    50: #e8ede7,
    100: #c5d2c4,
    200: #9eb49c,
    300: #779674,
    400: #598057,
    500: #3c6939,
    600: #366133,
    700: #2e562c,
    800: #274c24,
    900: #1a3b17,
    A100: #b1ff8f,
    A200: #8dff5c,
    A400: #6aff29,
    A700: #58ff10,
    contrast: (
        50: $dark-primary-text,
        100: $dark-primary-text,
        200: $dark-primary-text,
        300: $dark-primary-text,
        400: $light-primary-text,
        500: $light-primary-text,
        600: $light-primary-text,
        700: $light-primary-text,
        800: $light-primary-text,
        900: $light-primary-text,
        A100: $dark-primary-text,
        A200: $dark-primary-text,
        A400: $dark-primary-text,
        A700: $dark-primary-text,
    ),
);

$viso-error: (
    50: #f6e1e7,
    100: #e9b5c2,
    200: #db8499,
    300: #cd5370,
    400: #c22e52,
    500: #b70933,
    600: #b0082e,
    700: #a70627,
    800: #9f0520,
    900: #900214,
    A100: #ffc5d6,
    A200: #ff92b2,
    A400: #ff5f8e,
    A700: #ff467c,
    contrast: (
        50: $dark-primary-text,
        100: $dark-primary-text,
        200: $dark-primary-text,
        300: $dark-primary-text,
        400: $light-primary-text,
        500: $light-primary-text,
        600: $light-primary-text,
        700: $light-primary-text,
        800: $light-primary-text,
        900: $light-primary-text,
        A100: $dark-primary-text,
        A200: $dark-primary-text,
        A400: $dark-primary-text,
        A700: $dark-primary-text,
    ),
);

$viso-warning: (
    50: #fcfbe0,
    100: #f7f5b3,
    200: #f2ee80,
    300: #ede74d,
    400: #e9e226,
    500: #e5dd00,
    600: #e2d900,
    700: #ded400,
    800: #dacf00,
    900: #d3c700,
    A100: #ffffff,
    A200: #fffefc,
    A400: #fff2c9,
    A700: #ffecb0,
    contrast: (
        50: $dark-primary-text,
        100: $dark-primary-text,
        200: $dark-primary-text,
        300: $dark-primary-text,
        400: $dark-primary-text,
        500: $dark-primary-text,
        600: $dark-primary-text,
        700: $dark-primary-text,
        800: $dark-primary-text,
        900: $dark-primary-text,
        A100: $dark-primary-text,
        A200: $dark-primary-text,
        A400: $dark-primary-text,
        A700: $dark-primary-text,
    ),
);

$viso-info: (
    50: #e1f1f9,
    100: #b3dbf1,
    200: #81c4e8,
    300: #4eacdf,
    400: #289ad8,
    500: #0288d1,
    600: #0280cc,
    700: #0175c6,
    800: #016bc0,
    900: #0158b5,
    A100: #e3f4ff,
    A200: #b0e1ff,
    A400: #7dceff,
    A700: #64c4ff,
    contrast: (
        50: $dark-primary-text,
        100: $dark-primary-text,
        200: $dark-primary-text,
        300: $dark-primary-text,
        400: $light-primary-text,
        500: $light-primary-text,
        600: $light-primary-text,
        700: $light-primary-text,
        800: $light-primary-text,
        900: $light-primary-text,
        A100: $dark-primary-text,
        A200: $dark-primary-text,
        A400: $dark-primary-text,
        A700: $dark-primary-text,
    ),
);

$viso-surface-container: (
    50: #fefeff,
    100: #fcfefe,
    200: #fafdfd,
    300: #f8fcfc,
    400: #f7fbfc,
    500: #f5fafb,
    600: #f4f9fa,
    700: #f2f9fa,
    800: #f0f8f9,
    900: #eef6f8,
    A100: #ffffff,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
    contrast: (
        50: $surface-container-text,
        100: $surface-container-text,
        200: $surface-container-text,
        300: $surface-container-text,
        400: $surface-container-text,
        500: $surface-container-text,
        600: $surface-container-text,
        700: $surface-container-text,
        800: $surface-container-text,
        900: $surface-container-text,
        A100: $surface-container-text,
        A200: $surface-container-text,
        A400: $surface-container-text,
        A700: $surface-container-text,
    ),
);

$viso-brand: (
    primary-background: #01072c,
    primary-tint: #1a2041,
    black: #222222,
    black-tint: #383838,
    white: #f2f2f2,
    white-tint: #fdfdfd,
    blue: #4457e6,
    blue-shade: #3646b8,
    aqua: #65eaff,
    aqua-shade: #58d3e5,
    yellow: #fff500,
    yellow-shade: #e5dd00,
);

$viso-default-custom: (
    extreme-risk: #b70933,
    high-risk: #e47a3e,
    medium-risk: #2e71f3,
    low-risk: #17b5ab,
    no-context: #9ca8ad,
    black: #222222,
    white: #fdfdfd,
);
