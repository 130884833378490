.tag-color {
    font-weight: 500;
    border-radius: 2px;
    padding: 5px 10px;
    text-decoration: none;
    position: relative;
    border: 0px;
    &__remove {
        padding-right: 20px;
        &::after {
            font-family: 'Font Awesome 5 Free';
            font-weight: 900;
            content: '\f00d';
            right: 10px;
            position: absolute;
            font-size: 0.8em;
            bottom: 6px;
        }
    }

    &__remove-a {
        &::after {
            font-family: 'Font Awesome 5 Free';
            font-weight: 900;
            content: '\f00d';
            right: -10px;
            top: 0;
            position: absolute;
            font-size: 0.8em;
            background-color: inherit;
            height: 100%;
            padding: 8px 10px 5px 0;
            border-radius: 2px;
        }
    }
}

datatable-body-cell.tags-cell.datatable-body-cell {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    .datatable-body-cell-label {
        display: flex;
        align-items: center;
        height: 100%;
        position: relative;

        & > *:not(:last-child) {
            margin-right: 0.5rem;
        }
    }
}

app-vendor-tile {
    .tag-container > .tag-color {
        font-weight: normal !important;
        font-size: 0.75rem;
    }
}
