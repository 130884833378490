.mat-expansion-panel {
    &.supplemental {
        border-radius: 2px !important;
        border: 1px solid #c4c7c7 !important;
        box-shadow: none !important;
        background: #fafbfb !important;
    }
}

.mat-expansion-panel-header {
    &.supplemental {
        height: 56px !important;
    }
}

.mat-expansion-panel-content {
    &.supplemental {
        border-top: 1px solid #cfdbe2 !important;
        background-color: #fff !important;
    }
}
