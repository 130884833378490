@import 'variables';

.progress-container {
    display: inline-block;
}
progress {
    display: block;
    height: 50px;
    padding: 25px 0 0 0;
    margin: 0;
    margin-bottom: 0.625rem;
    background: none;
    border: 0;
    border-radius: 8px;
    text-align: left;
    position: relative;
    width: 100%;

    &.legend {
        height: 15px !important;
        padding: 0px !important;
        margin-bottom: 0px !important;
    }

    &.legend::-webkit-progress-bar {
        height: 12.6px !important;
        width: 186px !important;
        border-radius: 4.25px !important;
    }
    &.legend:after {
        display: none !important;
    }
    &.legend::-webkit-progress-value {
        display: none !important;
    }
}

progress::-webkit-progress-bar {
    height: 36px;
    width: 350px;
    padding-left: 0px;
    margin: 0 auto;
    background-color: $sensitivity-meter-bg;
    border-radius: 8px;
}

progress::-webkit-progress-value {
    display: inline-block;
    float: left;
    height: 40px;
    margin: 0px -10px 0 0;
    padding-left: 0.625rem;
    background: $sensitivity-meter-value-bg;
    border-radius: 8px;
}

progress:after {
    margin: -15px 0 0 -32px;
    padding: 0;
    display: inline-block;
    float: left;
    content: attr(value) '%';
    color: $sensitivity-meter-bg;
    font-weight: $sensitivity-meter-font-weight;
    font-size: 0.75rem;
    line-height: 1rem;
    letter-spacing: 0.5px;
}

progress.invert:after {
    margin-left: 1.25rem !important;
    color: $sensitivity-meter-label-invert !important;
}

.progress-markers {
    color: $sensitivity-meter-label;
    display: flex;
    justify-content: space-between;
    width: 100%;
    line-height: 1.25rem;

    .extreme {
        &.selected {
            font-weight: $sensitivity-meter-font-weight;
            color: $extreme-risk !important;
        }
    }
    .high {
        &.selected {
            font-weight: $sensitivity-meter-font-weight;
            color: $high-risk;
        }
    }
    .medium {
        &.selected {
            font-weight: $sensitivity-meter-font-weight;
            color: $medium-risk;
        }
    }
    .low {
        &.selected {
            font-weight: $sensitivity-meter-font-weight;
            color: $low-risk;
        }
    }
    .no-context {
        &.selected {
            font-weight: $sensitivity-meter-font-weight;
            color: $no-context;
        }
    }
}
