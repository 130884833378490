app-assessment-detail {
    .viso-tabset {
        height: inherit;
        display: flex;
        flex-direction: column;

        .nav-tabs {
            flex-shrink: 0;
            flex-grow: 0;

            .nav-link {
                color: rgba(0, 0, 0, 0.7);
            }
        }
    }
}
