.tab-container {
    &.viso-tabset {
        .nav-tabs {
            > .nav-item > .nav-link {
                background-color: transparent;
                border: 0;
                padding: 8px 20px;

                &.active {
                    color: $primary;
                    border-bottom: 1px solid $primary;
                }

                &.disabled {
                    color: #909fa7;
                    pointer-events: all;
                }
            }
        }

        .tab-content {
            padding: 0;
            border: 0;
        }
    }
    &.min-height .tab-content {
        min-height: 400px;
    }
    &.artifact-control-domains {
        .tab-content {
            min-height: 50px;
            margin-top: 1rem;
        }

        .nav-tabs {
            margin-left: 0 !important;
            margin-right: 0 !important;

            .control-domains-tab {
                a {
                    font-weight: 400;
                    .control-domains-count {
                        width: 25px;
                        padding: 2px 3px;
                        margin-left: 0.3rem;
                        color: #000;
                        background-color: #dfe6e9;
                        display: inline-flex;
                        justify-content: center;
                        font-size: 0.8rem;
                    }

                    &.active {
                        font-weight: 600;
                        .control-domains-count {
                            color: #fff;
                            background-color: #1a2041;
                        }
                    }
                }
            }
        }
    }
}

mat-tab-group.viso-tabset {
    .mat-mdc-tab-list {
        @extend .border-bottom;
    }
    .mat-mdc-tab {
        letter-spacing: normal;

        &.mat-mdc-tab-disabled {
            pointer-events: all;
            * {
                pointer-events: all;
            }
        }
    }
}
