@import '../../../shared/styles/app/variables';

%chart-base {
    pointbordercolor: #fff;
    pointhoverbackgroundcolor: #fff;
    pointbackgroundcolor: rgba(148, 159, 177, 1);
    pointhoverbordercolor: rgba(148, 159, 177, 0.8);
}
chart-info {
    @extend %chart-base;
    backgroundcolor: $info;
    bordercolor: $info;
}
chart-warning {
    @extend %chart-base;
    backgroundcolor: $warning;
    bordercolor: $warning;
}
chart-success {
    @extend %chart-base;
    backgroundcolor: $success;
    bordercolor: $success;
}
chart-danger {
    @extend %chart-base;
    backgroundcolor: $danger;
    bordercolor: $danger;
}
chart-light {
    @extend %chart-base;
    backgroundcolor: $light;
    bordercolor: $light;
}

$risk-no_context: $gray-light;
$risk-low: $success;
$risk-medium: $info;
$risk-high: $warning;
$risk-extreme: $danger;

$old-primary: #5d9cec;
$old-success: #27c24c;
$old-info: #23b7e5;
$old-warning: #ff902b;
$old-danger: #f05050;
$old-inverse: #131e26;
$old-green: #37bc9b;
$old-pink: #f532e5;
$old-purple: #7266ba;
$old-dark: #3a3f51;
$old-yellow: #fad732;
$old-gray-darker: #232735;
$old-gray-dark: #3a3f51;
$old-gray: #dde6e9;
$old-gray-light: #e4eaec;
$old-gray-lighter: #edf1f2;
$old-graph: #292f36; // For viso dark

:export {
    risk-no_context: $risk-no_context;
    risk-low: $risk-low;
    risk-medium: $risk-medium;
    risk-high: $risk-high;
    risk-extreme: $risk-extreme;
}
