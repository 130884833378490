app-relationship-context-modal {
    aw-wizard {
        aw-wizard-navigation-bar {
            margin: 0 -1rem;
            @extend .px-3;
            @extend .pb-3;
            @extend .border-bottom;
            > .steps-indicator {
                @extend .px-3;
            }

            &.horizontal.small {
                ul.steps-indicator {
                    li {
                        &:not(:last-child):after {
                            width: 350px;
                        }
                    }
                }
            }
        }
    }

    .checkbox-group-container {
        > mat-checkbox {
            height: 100%;
        }
    }
}
