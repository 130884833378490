.popover {
    &.disable-on-trial-popover,
    &.assessment-disabled-popover {
        width: auto !important;
        max-width: 350px;

        .popover-content {
            .popover-detail {
                padding: 0;

                .header {
                    padding: 1rem 0;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-direction: column-reverse;
                    min-height: 70px;
                    border-bottom: 1px solid #dfe6e9;
                }

                .body {
                    padding: 0.75rem 1rem;
                }

                .footer {
                    text-align: center;
                    padding: 1rem;
                    background-color: lighten($success, 58%);
                }
            }
        }
    }
}

.disabled.locked-feature-disabled-section {
    position: relative;
    @extend .text-muted;

    .trial-disable-icon {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
    }
}

.sidebar-nav {
    > li {
        > disable-on-trial-wrapper > span > a.disabled {
            @extend .text-muted;
        }
    }
}

disable-on-limit-wrapper {
    a,
    button {
        &.btn.disabled {
            opacity: 1;
            &.btn-outline-primary {
                color: rgba($primary, 0.6);
                border-color: rgba($primary, 0.6);
            }
        }
    }
}
