@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
    $color-config: mat.get-color-config($theme);
    $primary-palette: map.get($color-config, 'primary');
    $accent-palette: map.get($color-config, 'accent');
    $warn-palette: map.get($color-config, 'warn');
    $secondary-palette: map.get($color-config, 'secondary');
    $success-palette: map.get($color-config, 'success');

    $color-set: (
        'primary': mat.get-color-from-palette($primary-palette, default),
        'accent': mat.get-color-from-palette($accent-palette, default),
        'warn': mat.get-color-from-palette($warn-palette, default),
        'secondary': mat.get-color-from-palette($secondary-palette, default),
        'success': mat.get-color-from-palette($success-palette, default),
    );

    @each $name, $color in $color-set {
        .mat-text-#{$name} {
            color: $color;
        }
    }
}
