/*!
 *
 * Angle - Bootstrap Admin App + AngularJS
 *
 * Version: @version@
 * Author: @author@
 * Website: @url@
 * License: @license@
 *
 */

// Bootstrap
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
// Variables
@import 'app/variables';
// Layout
@import 'app/layout';
@import 'app/layout-extra';
@import 'app/layout-animation';
@import 'app/top-navbar';
@import 'app/sidebar';
@import 'app/offsidebar';
@import 'app/user-block';
@import 'app/settings';
// Common
@import 'app/typo';
// todo: migrate away from forms that were supposed to be deprecated with bootstrap 5
@import 'app/bootstrap_forms_mixins';
@import 'app/bootstrap_forms';
@import 'app/bootstrap-reset';
@import 'app/bootstrap-custom';
@import 'app/button-extra';
@import 'app/placeholder';
@import 'app/cards';
@import 'app/variables-chartjs';
@import 'app/dropdown-extra';
@import 'app/half-float';
@import 'app/animate';
@import 'app/slim-scroll';
@import 'app/icons';
@import 'app/inputs';
@import 'app/utils';
@import 'app/print';
// Elements
@import 'app/spinner';
// Charts
//@import "app/radial-bar";
// @import "app/chart-flot";
@import 'app/chart-easypie';
// Form elements
// @import "app/form-select2";
// @import "app/form-tags-input";
// @import "app/file-upload";
// @import "app/summernote";
// @import "app/typeahead";
// Tables
// @import "app/table-extras";
// @import "app/table-angulargrid";
// Maps
// @import "app/gmap";
// @import "app/vector-map";
// Extras
@import 'app/timeline';
// @import "app/todo";
// @import "app/calendar";
// @import "app/mailbox";
// @import "app/plans";

// Quill Editor
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css';
@import '~quill-mention/dist/quill.mention.css';
@import 'app/custom-quill';
@import 'app/archwizard';
@import 'app/custom_datatable';
@import 'app/custom-tabset';
@import 'app/custom-forms';
@import 'app/custom-accordion';
@import 'app/custom-anchor';
@import 'app/custom-slider';
@import 'app/custom-badge';
@import 'app/custom-popover';
@import 'app/custom-mat-drawer';
@import 'app/pdf-export';
@import 'app/relationship-new-edit-modal';
@import 'app/relationship-context-modal';
@import 'app/relationship-detail-page';
@import 'app/your-organization-page';
@import 'app/action-notification';
@import 'app/subscribe-contact-modal';
@import 'app/business-cases-colors';
@import 'app/custom-card';
@import 'app/relationship-lists-page';
@import 'app/assessment-collection-page';
@import 'app/notifications';
@import 'app/graph-colors';
@import 'app/start-assessment-modal';
@import 'app/custom-dropdown';
@import 'app/custom-modal';
@import 'app/disable-on-limit';
@import 'app/assessment-detail';
@import 'app/tags';
@import 'app/announcement';
@import 'app/custom-pdf-viewer';
@import 'app/security-control-domains-component';
@import 'app/custom-progressbar';
@import 'app/custom-typo';
@import 'app/custom-mat-list';
@import 'app/custom-chip';
@import 'app/custom-mat-expansion-panel';
@import 'app/recommendations-list';
@import 'app/help-docs';
@import 'app/ngx-guided-tour';
@import 'app/assessment-list-item';
@import 'app/vendor-tier-stats';
@import 'app/vendor-risk-events';
