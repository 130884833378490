@mixin stripes_background($base_color, $stripe_alfa: 0.2) {
    background: $base_color
        repeating-linear-gradient(
            45deg,
            rgba(0, 0, 0, $stripe_alfa),
            rgba(0, 0, 0, $stripe_alfa) 2px,
            transparent 2px,
            transparent 10px
        );
}
