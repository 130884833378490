@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
    $color-config: mat.get-color-config($theme);
    $error-palette: map.get($color-config, 'warn');

    // Additional palettes
    $warning-palette: map.get($color-config, 'warning');
    $success-palette: map.get($color-config, 'success');
    $info-palette: map.get($color-config, 'info');

    $success: mat.get-color-from-palette($success-palette, default);
    $warning: mat.get-color-from-palette($warning-palette, default);
    $error: mat.get-color-from-palette($error-palette, default);
    $info: mat.get-color-from-palette($info-palette, default);

    .mat-mdc-snack-bar-container {
        --mat-snack-bar-button-color: var(--mdc-snackbar-supporting-text-color);

        .mdc-snackbar__actions {
            margin-left: 1rem;
        }

        .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled) {
            height: 28px;
            outline: 1.5px solid var(--mdc-snackbar-supporting-text-color) !important;
            border-radius: 3px;
        }

        &.snack-success {
            --mdc-snackbar-container-color: #{$success};
        }

        &.snack-error {
            --mdc-snackbar-container-color: #{$error};
        }

        &.snack-warning {
            --mdc-snackbar-container-color: #{$warning};
        }

        &.snack-info {
            --mdc-snackbar-container-color: #{$info};
        }
    }
}

@mixin typography($theme) {
    $typography-config: mat.get-typography-config($theme);
}

@mixin theme($theme) {
    $color-config: mat.get-color-config($theme);
    @if $color-config != null {
        @include color($theme);
    }

    $typography-config: mat.get-typography-config($theme);
    @if $typography-config != null {
        @include typography($theme);
    }
}
