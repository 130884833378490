@import './risk-colors';
@import './variables';
@import './mixins';

.badge-square {
    font-size: 0.79rem;
    font-weight: 400;
    flex-direction: row;
    align-items: center;
    padding: 4px 5px;
    border-radius: 2px;
}

@each $color, $value in $theme-colors {
    .badge-#{$color}.transitional {
        @include stripes_background($value);
    }
}

.beta-badge {
    background-color: #4457e6;
    color: white;
    padding: 2.5px 7.5px;
    margin-left: 1rem;
    border-radius: 2px;
}
