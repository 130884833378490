@import 'bootstrap/scss/variables';
@import '../../styles/app/variables.scss';

aw-wizard {
    &.assessment-wizard.privacy-step-enabled {
        @media (min-width: 767.98px) {
            app-dynamic-error-message {
                top: 265px;
            }
        }
    }
    &.assessment-wizard {
        position: relative;
        display: flex;
        justify-content: center;
        app-dynamic-error-message {
            visibility: hidden;
            position: absolute;
        }
        @media (min-width: 767.98px) {
            display: flex;
            justify-content: center;
            app-dynamic-error-message {
                visibility: visible;
                position: initial;
                position: absolute;
                left: 0;
                top: 240px;
            }

            aw-wizard-navigation-bar {
                width: auto !important;
                position: absolute;
                top: 0;
                left: 0;
            }

            .wizard-steps.vertical {
                width: auto !important;
                min-width: auto;
                padding-right: 0;
                padding-left: 220px;
            }

            aw-wizard-step {
                display: initial;
            }
        }

        @media (min-width: 1200px) {
            .wizard-steps.vertical {
                padding: 0 280px;
            }
        }

        aw-wizard-navigation-bar {
            &.vertical ul.steps-indicator li .label {
                margin-left: 0;
            }
            ul.steps-indicator {
                margin-left: 20px;
                @media (max-width: 767.98px) {
                    justify-content: start !important;
                    padding-bottom: 0 !important;
                    padding-top: 0.4rem !important;

                    li:not(.current) {
                        display: none;
                    }

                    li:last-child {
                        padding-bottom: 1.4rem !important;
                    }
                }

                i.fas {
                    font-weight: 900;
                    position: absolute;
                    top: 2px;
                    left: -20px;
                }

                .step-complete {
                    color: $success;
                }

                .step-error {
                    color: #a81e1e;
                }

                li {
                    .label {
                        text-transform: none;
                        font-size: 1rem;
                        font-weight: 400;
                        line-height: 1rem;
                        color: #8c8c8c;
                    }

                    .step-indicator {
                        left: -0.812rem !important;
                        width: 0.125rem !important;
                        border-radius: 0 !important;
                        background-color: #e0e0e0 !important;
                    }
                }

                li.current {
                    @media (max-width: 767.98px) {
                        text-align: left;
                        width: 100% !important;
                    }

                    .label {
                        color: #262626;
                    }

                    .step-indicator {
                        left: -0.875rem !important;
                        width: 0.25rem !important;
                        height: 1.375rem !important;
                        background-color: $primary !important;

                        @media (max-width: 767.98px) {
                            display: none;
                        }
                    }
                }

                li.done {
                    .label {
                        color: #262626;
                    }
                }

                li:not(:first-child).current .step-indicator {
                    top: -4px;
                }

                li:not(:last-child) {
                    padding-bottom: 1.4rem !important;

                    &::after {
                        top: 0px;
                        position: absolute;
                        height: 14px;
                        text-align: center;
                        vertical-align: middle;
                        line-height: 14px;
                        transition: 0.25s;
                        left: -0.812rem !important;
                        width: 0.125rem !important;
                        border-radius: 0 !important;
                        background-color: #e0e0e0 !important;
                        z-index: -1;
                    }

                    &:before {
                        left: -0.812rem !important;
                        width: 0.125rem !important;
                        background-color: #e0e0e0 !important;
                    }
                }
            }
        }
    }

    &.hidden {
        ul.steps-indicator {
            visibility: hidden;
        }
    }

    aw-wizard-navigation-bar.horizontal.small {
        ul.steps-indicator {
            justify-content: space-between;
            overflow: hidden;
            li {
                width: auto;
                .label {
                    text-transform: none;
                }
                .label:hover {
                    color: $primary !important;
                }

                .step-indicator {
                    background-color: white;
                    border: 1px solid $gray;
                    line-height: 12px !important;
                }

                &.current,
                &.done {
                    .step-indicator {
                        border: 1px solid $primary;
                    }
                }

                &.done {
                    .label {
                        color: $black;
                    }
                }

                &.current {
                    .label {
                        color: $primary;
                    }
                    .step-indicator,
                    a:hover .step-indicator {
                        background-color: #c7f9ed;
                    }
                }

                &:not(:last-child):after {
                    width: 300px;
                }

                &:last-child:after {
                    width: 50px;
                    background-color: white;
                    content: '';
                    position: absolute;
                    height: 3px;
                    width: calc(100% - 14px);
                    top: -8px;
                    left: calc(50% + 7px);
                }

                &.done,
                &.optional {
                    &:not(:last-child):after,
                    .step-indicator,
                    a:hover .step-indicator {
                        background-color: $primary !important;
                    }

                    &:not(.current) .step-indicator::after {
                        font-family: 'Font Awesome 5 Free';
                        font-weight: 900;
                        color: #ffffff;
                        font-size: 80%;
                        content: '\F00C';
                    }

                    &.current .step-indicator {
                        background-color: #c7f9ed !important;
                    }
                }
            }
        }
    }
}
