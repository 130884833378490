@use '../palette';
.ngx-slider.viso-slider {
    .ngx-slider-pointer {
        width: 8px !important;
        height: 16px !important;
        top: auto !important; /* to remove the default positioning */
        bottom: 0 !important;
        padding-left: 3px !important;
        background-color: #333 !important;
        border-top-left-radius: 3px !important;
        border-top-right-radius: 3px !important;

        &:after {
            display: none !important;
        }
    }

    .ngx-slider-bubble {
        bottom: 14px !important;
    }

    .ngx-slider-selection {
        background: linear-gradient(to right, $white 0%, map-get($viso-primary, 500) 100%) !important;
    }
}

app-controls-config,
app-data-types-config {
    .ngx-slider .ngx-slider-bubble {
        font-size: 0.7rem !important;
    }
}
