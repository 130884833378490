@use '../palette';

/* Color overrides */
$tour-orb-color: map-get(palette.$viso-warning, 500);
$tour-next-button-color: map-get(palette.$viso-primary, 500);
$tour-next-button-hover: map-get(palette.$viso-primary, 400);

.guided-tour-spotlight-overlay {
    border-radius: 5px !important;
}

.tour-block {
    border-radius: 5px !important;
    font-size: 0.875rem !important;
}

.tour-content {
    padding: 0 0 1rem 0 !important;
    min-height: 0 !important;
}

.tour-title {
    padding: 0.5rem 0 !important;
}

.tour-buttons {
    button {
        padding: 0.5rem 0.9rem !important;
        border-radius: 5px !important;
    }

    .back-button {
        color: map-get(palette.$viso-primary, 500) !important;
        margin-right: 0.5rem;
    }
}

/* Import at the end to override colors */
@import '../node_modules/ngx-guided-tour/scss/guided-tour-base-theme.scss';
