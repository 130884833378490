@use 'sass:map';
@use '@angular/material' as mat;
@use '../palette';
@import '../app/variables.scss';

@mixin color($theme) {
    $color-config: mat.get-color-config($theme);
    $primary-palette: map.get($color-config, 'primary');
    $secondary-container: map.get($color-config, 'secondary-container');
}

@mixin typography($theme) {
    $typography-config: mat.get-typography-config($theme);

    .mat-button-toggle-appearance-standard span.mat-button-toggle-label-content {
        line-height: 2.3rem;
    }

    .mat-button-toggle {
        font-size: 14px !important;
        line-height: 100%;
    }
}

@mixin theme($theme) {
    $color-config: mat.get-color-config($theme);
    @if $color-config != null {
        @include color($theme);
    }

    $typography-config: mat.get-typography-config($theme);
    @if $typography-config != null {
        @include typography($theme);
    }

    .tabbed-button-toggle.mat-button-toggle-group-appearance-standard {
        border: none;
        border-radius: 0;

        .mat-button-toggle.mat-button-toggle-appearance-standard {
            align-content: center;
            background-color: $button-toggle-group-background-color;
            border-radius: 4px 4px 0 0;
            color: $button-toggle-group-color;
            height: 36px;

            .mat-ripple {
                border-radius: 4px 4px 0 0;
            }

            &.mat-button-toggle-checked {
                background-color: $button-toggle-group-checked-background-color;
                color: $button-toggle-group-checked-text-color;
            }
        }
    }

    .pilled-button-toggle.mat-button-toggle-group-appearance-standard {
        border-radius: 20px;

        .mat-button-toggle-checked {
            background-color: map-get(palette.$viso-secondary-container, 300);
        }

        .mat-pseudo-checkbox {
            width: 10px;
            height: 10px;

            &:after {
                width: 10px;
                height: 4px;
            }
        }

        mat-icon {
            transform: scale(0.8);
        }
    }
}
