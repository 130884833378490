a {
    &.viso-link {
        text-decoration: underline !important;
        &:hover {
            cursor: pointer !important;
        }
    }
}

a {
    &.viso-link-hover {
        &:hover {
            text-decoration: underline !important;
            cursor: pointer !important;
        }
    }
}
