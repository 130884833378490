/* ========================================================================
     Component: print
 ========================================================================== */

// Put here all rules required to style the print view

@media print {
    .wrapper .footer-container {
        display: none;
    }
    .wrapper .navbar {
        display: none;
    }
    .wrapper .aside-container {
        display: none;
    }
    .wrapper .section-container {
        margin-left: 0 !important;
        margin-bottom: 0 !important;
        margin-top: 0 !important;
    }
    .content-wrapper .content-heading {
        display: none;
    }

    .stat {
        border: solid 1px lightgray;
    }

    .avoid-break-inside {
        break-inside: avoid !important;
    }

    viso-snackbar {
        display: none;
    }
}
