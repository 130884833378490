ngx-select {
    ngx-select-choices {
        ul.dropdown-menu {
            &.line-break {
                li.ngx-select__item-group > a.ngx-select__item {
                    white-space: pre-wrap;
                }
            }

            &.sticky-last-option {
                li.ngx-select__item-group:last-child {
                    position: sticky;
                    bottom: -5px;
                    left: 0;
                    right: 0;
                    background: #ffffff;
                    border-top: 1px solid $gray;
                    padding: 3px 0;
                }
            }
        }
    }
}

ngx-select.control-status-select {
    .ngx-select__toggle {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}

ngx-select.control-assigned {
    .ngx-select__item {
        padding-left: 25px;
    }

    .dropdown-header {
        color: #000;
        font-weight: 600;
        padding-left: 15px;
    }
}

.dropdown-item:active {
    background-color: rgba(83, 90, 146, 0.12);
}

.dropdown-item:hover {
    background-color: rgba(83, 90, 146, 0.08);
}
