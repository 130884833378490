@use 'sass:map';
@use '@angular/material' as mat;
@import '../app/variables';

@mixin theme($theme) {
    $color-config: mat.get-color-config($theme);
    $primary-palette: map.get($color-config, 'primary');
    $accent-palette: map.get($color-config, 'accent');
    $warn-palette: map.get($color-config, 'warn');

    // Additional palettes
    $secondary-palette: map.get($color-config, 'secondary');
    $success-palette: map.get($color-config, 'success');

    .mat-mdc-fab {
        height: 40px !important;
    }

    .mat-mdc-fab:not(:disabled) {
        @extend .mat-elevation-z0;

        &:hover,
        &:active {
            @extend .mat-elevation-z0;
        }

        &:focus {
            box-shadow: none !important;
        }

        &.fab-primary {
            color: $body-bg;
            background: $fab-bg-primary;
        }
        &.fab-secondary {
            color: $body-bg;
            background: $fab-bg-secondary;
        }
        &.fab-outlined {
            color: $fab-fg-outlined;
            border: 1px solid $fab-outline;
            background: $body-bg;
        }
    }
}
