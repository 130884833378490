.app-card {
    box-sizing: border-box;
    border: 1px solid #d2dbe1;
    padding: 0;
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    max-height: 100%;
    background-color: #fff;
    border-radius: 2px;
}

.app-card--full-screen {
    position: absolute;
    top: 8px;
    right: 16px;
    bottom: 8px;
    left: 16px;
    background: #fff;
    z-index: 1;
}

.app-card-toolbar {
    padding: 0 16px;
    display: flex;
    min-height: 40px;
    align-items: center;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    background-color: #fff;
    color: rgba(0, 0, 0, 0.7);
}

.app-card-toolbar__section {
    display: inline-flex;
    flex: 1;
    align-items: center;
    justify-content: flex-start;
}

.app-card-toolbar__section--align-end {
    justify-content: flex-end;
    order: 1;
}

.app-card-toolbar__button {
    margin: 0 0.5rem;
}

.app-card-toolbar__button:first-of-type {
    margin-left: 0;
}

.app-card-toolbar__button:last-of-type {
    margin-right: 0;
}

.app-card-toolbar__item {
    margin: 0 0.5rem;
}

.app-card-toolbar__item:first-child {
    margin-left: 0;
}

.app-card-toolbar__item:last-child {
    margin-right: 0;
}

.app-card-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    max-height: 100%;
    padding: 15px;
}

.app-card-content--relative {
    position: relative;
}
