.modal-container .modal-dialog {
    max-width: 45%;
    width: 45%;

    @media (max-width: 767.98px) {
        max-width: 100%;
        width: unset;
    }
}

// fix for backdrop going over everything. check to see if still necessary after all the updates
.modal-backdrop.show {
    z-index: 1050 !important;
}

@media (min-width: 992px) {
    .modal-xxl {
        --bs-modal-width: 800px;
    }
}

@media (min-width: 1200px) {
    .modal-xxl {
        --bs-modal-width: 1140px;
    }
}

@media (min-width: 1400px) {
    .modal-xxl {
        --bs-modal-width: 1340px;
    }
}
