@use 'sass:map';
@use '@angular/material' as mat;
@use '@angular/material/form-field/form-field-theme' as form-field-theme;
@use '../palette';

@mixin color($theme) {
    $color-config: mat.get-color-config($theme);
    $neutral-color: map.get($color-config, 'neutral');
    $primary-color: map.get($color-config, 'primary');
    $viso-accent-color: map.get($color-config, 'accent');

    mat-form-field.mat-mdc-form-field {
        &.search-form-field {
            .mat-mdc-form-field-infix {
                min-height: auto;
                padding: 0;
            }
        }
    }

    .outline-checkbox {
        border: 1.5px solid mat.get-color-from-palette($neutral-color, 200);

        &.mat-primary.mat-mdc-checkbox-checked {
            background-color: map-get(palette.$viso-surface-container, 500);
            color: palette.$surface-container-text;
            border-color: currentColor;
        }
    }

    .mdc-text-field.mdc-text-field--filled:not(.mdc-text-field--disabled) {
        background-color: initial;
    }
}

@mixin typography($theme) {
    $typography-config: mat.get-typography-config($theme);

    .text-bold label {
        font-weight: bold;
    }
}

@mixin theme($theme) {
    $color-config: mat.get-color-config($theme);
    @if $color-config != null {
        @include color($theme);
    }

    $typography-config: mat.get-typography-config($theme);
    @if $typography-config != null {
        @include typography($theme);
    }
}

.mat-mdc-radio-button > .mdc-form-field > label,
.mat-mdc-checkbox > .mdc-form-field > label,
.mat-mdc-slide-toggle > .mdc-form-field > label {
    margin-bottom: 0;
}

.mat-mdc-checkbox {
    &.full-width {
        width: 100%;

        .mdc-form-field {
            width: inherit;
        }
    }

    &.start-aligned {
        margin-left: -11px;
    }

    &.top-aligned {
        margin-top: -11px;
    }

    &.attestation-checkbox {
        label {
            font-size: 1rem;
        }
        .mdc-form-field {
            color: unset;
        }
    }

    &.extra-small {
        --mdc-checkbox-state-layer-size: 14px;
    }

    &.small {
        --mdc-checkbox-state-layer-size: 18px;

        .mdc-checkbox {
            margin-right: 0.3rem;
        }

        label {
            letter-spacing: normal;
        }
    }
    &.right-checkbox {
        .mdc-checkbox {
            align-self: baseline;
        }

        .mdc-form-field > label {
            padding: 8px 10px 8px 10px;
        }
    }

    &.outline-checkbox {
        border-radius: 3px;
        .mdc-form-field {
            width: 100%;
            cursor: pointer;
        }
        .mdc-form-field > label {
            align-self: center;
            margin-right: auto;
            margin-left: 0;
            padding-right: 0px;
            width: 100%;
            letter-spacing: normal;
        }
    }
}

.mat-mdc-slide-toggle {
    &.full-width {
        width: 100%;
        .mdc-form-field {
            width: inherit;
        }
    }
    &.space-between {
        .mdc-form-field {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
    &.reverse {
        .mdc-form-field {
            flex-direction: row-reverse;
        }
    }
}

mat-form-field.dense-3 {
    @include form-field-theme.density(-3);
}

.mdc-text-field {
    font-size: 0.875rem !important; // 14px
    color: inherit !important;
    letter-spacing: normal !important;
}
