.viso-accordion {
    accordion-group {
        margin-bottom: 1rem;

        .panel.card.panel-default {
            margin-bottom: 0px;
            border: 1px solid #cccccc;
            border-radius: 2px;
            box-shadow: none;
        }

        .panel-collapse .panel-body {
            padding: 0;
        }
    }

    &.artifact-control-domains {
        .panel .panel-heading {
            background-color: #fafbfc;
            padding: 0;
        }

        .panel-collapse .panel-body {
            background-color: #fafbfc;
        }
    }

    &.validated {
        .accordion-toggle {
            background-color: #f5fafa;
        }

        .panel-collapse .panel-body {
            background-color: #f5fafa;
        }
    }
}
