pdf-viewer > ngx-extended-pdf-viewer {
    .body :is(.toolbar, .editorParamsToolbar, .findbar, #sidebarContainer) :is(input, button, select) {
        font-family: 'Niveau Grotesk';
    }
    .grab-to-pan-grab .pdfViewer {
        padding: 1rem;
    }
    .textLayer .highlight.selected {
        background-color: #fff500;
    }
}
